<template>
    <div class='col-md-8 offset-md-2'>
        <v-overlay :value="overlay" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-card class='mt-md-10'>
            <v-form ref='form_request_advance' v-if="access">
                <v-col>
                    <v-card tile class='text-center' color='blue lighten-1 white--text py-2 mx-4'>
                        REAGENDAMIENTO DE ENVÍO DE PAQUETE DE LA COTIZACIÓN
                    </v-card>
                    <v-row >
                        <v-col cols=12 class="mt-5">
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title><b>COTIZACIÓN </b></v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{dataShipping.quote_id}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title><b>CLIENTE </b></v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{dataShipping.cliente}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title><b>DIRECCIÓN </b></v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{dataShipping.direccion}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title><b>ATIENDE </b></v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-text-field
                                            v-model="dataShipping.attn_name"
                                            dense
                                            class="mt-0 input-quotation"
                                            style="height:25px"
                                        ></v-text-field> 
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-content>
                                <v-list-item-title><b>Teléfono</b> </v-list-item-title>
                                <v-list-item-subtitle>
                                    <v-text-field
                                        v-model="dataShipping.tlf_attn_name"
                                        dense
                                        class="mt-0 input-quotation"
                                        style="height:25px"
                                    ></v-text-field> 
                                </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title><b>Instrucciones</b></v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-text-field
                                            v-model="dataShipping.instrucciones"
                                            dense
                                            class="mt-0 input-quotation"
                                            style="height:25px"
                                        ></v-text-field> 
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title><b>Enviar despues de:</b></v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-text-field
                                            v-model="dataShipping.entrega_desde"
                                            dense
                                            type=datetime-local
                                            class="mt-0 input-quotation"
                                            style="height:25px"
                                        ></v-text-field> 
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>           
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title><b>Entregar antes de:</b></v-list-item-title>
                                    <v-list-item-subtitle>
                                        <v-text-field
                                            v-model="dataShipping.entrega_hasta"
                                            dense
                                            type=datetime-local
                                            class="mt-0 input-quotation"
                                            style="height:25px"
                                        ></v-text-field> 
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>    
                        </v-col>
                    </v-row>
                    <v-row v-if="statusWhorkEffort =='CAL_TAR_PLANIFICADO'">
                        <v-col class="text-center">
                            <v-btn
                                color="primary"
                                class='mr-md-2'
                                @click="completeWorkEffort"
                            >
                                <v-icon>mdi-check-all</v-icon> REAGENDAR
                            </v-btn>
                           
                        </v-col>
                    </v-row>
                    <v-col v-else>
                        <v-alert
                            dense
                            color="info"
                            class="text-uppercase text-center"
                            dark
                        >
                            {{descriptionStatus}}
                        </v-alert>
                    </v-col>
                </v-col>
            </v-form>
            <v-col v-else class='deep-orange darken-1 white--text'>
                <v-row align="center" >
                    <v-col>
                        <v-icon class='white--text'>mdi-sync-alert</v-icon> Usted no es el propietario de la tarea, por lo que o puede completarla
                    </v-col>
                    <v-col class="shrink">
                    <v-btn to='/' color='primary'>Volver a tareas</v-btn>
                    </v-col>
                </v-row>
            </v-col >
        </v-card>
    </div>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex'
import Vue from 'vue'
import moment from 'moment'

export default {
    name: 'ReagendaCotizacionComponent',
    data: ()=>({
        access: false,
        dataShipping:{},
        statusWhorkEffort: null,
        descriptionStatus:null
    }),
    computed:{
        
        ...mapState('master',['paramAlertQuestion','overlay'])

    },
    methods:{

        ...mapMutations('master',['setUrl','setOverlay']),

        ...mapActions('master',['requestApi','alertNotification']),

        verifyWorkEffort(){

            this.setOverlay(true)
            this.setUrl('estado-cotizacion')
            this.requestApi({
                method: 'POST',
                data: { 
                    workEffortId: this.$route.params.work_effort_id,
                    orderId: this.$route.params.order_id,
                    typeStore: 'verifyWorkEffort'
                }
            }).then(res =>{
                
                this.access = res.data.res.exist
                this.statusWhorkEffort= res.data.res.currentStatusId
                this.descriptionStatus = res.data.res.statusWorkEffort

                if(res.data.res.dataShipping!=null){

                    res.data.res.dataShipping.entrega_hasta =moment(res.data.res.dataShipping.entrega_hasta).format('YYYY-MM-DDTHH:mm')
                    res.data.res.dataShipping.entrega_desde=  moment(res.data.res.dataShipping.entrega_desde).format('YYYY-MM-DDTHH:mm')
                    this.dataShipping = res.data.res.dataShipping
                    
                }

                this.setOverlay(true)
                
            }).catch(()=>{
                
            }).then(()=>{
               this.setOverlay(false)
            })

        },

        completeWorkEffort(){
            
            Vue.swal({
                html: "Está seguro de reagendar el envío del pedido?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Aceptar',
                cancelButtonText: 'Cerrar',
                ...this.paramAlertQuestion
            }).then( result => {

                if (result.isConfirmed) {

                    this.setUrl('estado-cotizacion')
                    this.requestApi({
                        method: 'POST',
                        data: {
                            typeStore: 'storeRescheduleQuote',
                            workEffortId: this.$route.params.work_effort_id,
                            orderId: this.$route.params.order_id,
                            ...this.dataShipping
                        }
                    }).then(res =>{

                        console.log(res)
                        this.alertNotification({param:{html: res.data.msg}})   
                        this.verifyWorkEffort()
                      
                    })

                }

            })

        },

    },
    created(){
        this.verifyWorkEffort()
    }
}
</script>